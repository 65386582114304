import { isEqual, keys, reduce, union } from 'lodash'

/*!
 * Find the differences between two objects and push to a new object
 * @param  {Object} obj1 The original object
 * @param  {Object} obj2 The object to compare against it
 * @return {Object}      An object of differences between the two
 */
export const getObjDifferences = (
  obj1: { [key: string]: any },
  obj2: { [key: string]: any } = {},
) => {
  const allKeys = union(keys(obj1), keys(obj2))
  const diff = reduce(
    allKeys,
    (result: Record<string, any>, key: string) => {
      if (!isEqual(obj1[key], obj2[key])) {
        result[key] = obj1[key] ?? obj2[key]
      }
      return result
    },
    {},
  )

  return diff
}
