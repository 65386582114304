import React from 'react'
import {
  Button,
  ComposedModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@carbon/react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UserRoleBinding as ApiUserRoleBinding } from '@cloudnatix-types/usercontroller'
import { useCreateUser, useDeleteUser, useUpdateUser } from 'src/api'
import { Confirm } from 'src/next/components/Dialogs/Confirm'
import { InlineNotification } from 'src/next/components/InlineNotification'
import Loading from 'src/next/components/Loading'
import useToaster from 'src/next/hooks/useToaster'
import { UserForm } from './UserForm'
import { UserTableRowData } from './UsersTable'

interface UserActionsModalProps {
  open: boolean
  isDelete: boolean
  onClose: () => void
  user: UserTableRowData | null
}

interface UserRoleBinding extends ApiUserRoleBinding {
  orgName?: string
}

export interface UserFormValues {
  firstName: string
  lastName: string
  loginId: string
  roleBindings: UserRoleBinding[]
}

export const UserActionsModal = ({
  open,
  isDelete,
  onClose,
  user,
}: UserActionsModalProps) => {
  const { t } = useTranslation()
  const { addToast } = useToaster()

  const isEditMode = !!user

  const deleteUser = useDeleteUser()

  const {
    mutate: createUser,
    isError: isErrorCreate,
    isLoading: isLoadingCreate,
  } = useCreateUser()

  const {
    mutate: updateUser,
    isError: isErrorUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateUser()

  const handleSuccess = () => {
    addToast({
      title: isEditMode
        ? t('Users.Edit.SuccessAll_one')
        : t('Users.Create.Success'),
      kind: 'success',
    })
    onClose()
  }

  const onSubmit = async (newUser: UserFormValues) => {
    if (user) {
      updateUser(
        {
          uuid: user.id,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          roleBindings: newUser.roleBindings,
        },
        {
          onSuccess: handleSuccess,
        },
      )
    } else {
      createUser(newUser, {
        onSuccess: handleSuccess,
      })
    }
  }

  const deleteToast = (isSuccess: boolean) => {
    addToast({
      title: isSuccess ? t('Users.Delete.Success') : t('Users.Delete.Error'),
      kind: isSuccess ? 'success' : 'error',
    })
  }

  const handleDelete = async () => {
    deleteUser.mutate(user!.id, {
      onSuccess: () => {
        deleteToast(true)
        onClose()
      },
      onError: () => deleteToast(false),
    })
  }

  const defaultValues = isEditMode
    ? user
    : {
        firstName: '',
        lastName: '',
        loginId: '',
        roleBindings: [],
      }

  const formMethods = useForm<UserFormValues>({
    defaultValues,
  })

  return (
    <>
      {isDelete ? (
        <Confirm
          open={open}
          danger
          primaryButtonText={t('Common.Delete')}
          modalHeading={
            <>
              {t('Users.ConfirmDelete')}
              {deleteUser.isLoading && <Loading withOverlay size="medium" />}
            </>
          }
          onClose={onClose}
          onCancel={onClose}
          onConfirm={handleDelete}
        />
      ) : (
        <ComposedModal
          size="sm"
          open={open}
          onClose={onClose}
          preventCloseOnClickOutside
        >
          {(isLoadingCreate || isLoadingUpdate) && (
            <Loading withOverlay size="medium" />
          )}

          <ModalHeader
            title={isEditMode ? t('Users.EditUser') : t('Users.AddUser')}
          />
          <ModalBody>
            {isErrorCreate || isErrorUpdate ? (
              <InlineNotification
                kind="error"
                title={
                  isEditMode
                    ? t('Users.Edit.SuccessNotAll_one')
                    : t('Users.Create.Error')
                }
              />
            ) : null}
            <FormProvider {...formMethods}>
              <form
                onSubmit={formMethods.handleSubmit(onSubmit)}
                id="org-user-form"
              >
                <UserForm defaultLoginId={defaultValues.loginId} />
              </form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} kind="secondary">
              {t('Common.Cancel')}
            </Button>
            <Button form="org-user-form" kind="primary" type="submit">
              {isEditMode ? t('Common.Update') : t('Common.Create')}
            </Button>
          </ModalFooter>
        </ComposedModal>
      )}
    </>
  )
}
