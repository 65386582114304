import { useMemo } from 'react'
import { Dropdown } from '@carbon/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  ComputeInstanceGroupingKeyKey,
  SummaryInterval,
} from '@cloudnatix-types/dashboard'
import { useInstanceSummariesRollupQuery } from 'src/api'
import { computeInstanceSummaryMetricMap } from 'src/next/constants/summary'
import { Box, Flex } from 'src/next/components'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { useComputeInstanceSummariesMetricsDropdownItems } from 'src/next/components/dashboard/tabs/charts/useFilterOptions'
import { CollapseComponent } from 'src/next/components/ui'
import { TimePeriod } from 'src/next/utils/time'
import { useDataTableLegend } from 'src/next/hooks/useDataTableLegend'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'
import { createFormatFn, getFormatType } from 'src/next/utils/graph.utils'
import { TabDataTable } from '../tables'
import { transformComputeInstanceGroupingData } from '../tables/transformComputeInstanceGroupingData'
import { useGroupingHeaders } from '../tables/useGroupingTable'
import { GenericTabTrendViewChart } from './GenericTabTrendViewChart'
import { useTimeRangeOptions } from './useFilterOptions'

const getStartAndEndTime = (timePeriod: TimePeriod) => {
  const lastHour = dayjs().startOf('hour')

  const subtract: [number, dayjs.ManipulateType] =
    timePeriod === 'daily'
      ? [1, 'month']
      : timePeriod === 'weekly'
      ? [6, 'months']
      : timePeriod === 'monthly'
      ? [1, 'year']
      : [1, 'month']

  const startTimeNs = `${lastHour.subtract(...subtract).valueOf() * 1_000_000}`
  const endTimeNs = `${lastHour.valueOf() * 1_000_000}`

  return { startTimeNs, endTimeNs }
}

const useDurationString = (timePeriod: TimePeriod) => {
  const { t } = useTranslation()

  // Call the `t` function here than inside the backquoted texts below to get them captured by `yarn translations:scan`.
  const month = t('Common.Time.Month')
  const months = t('Common.Time.Months')
  const year = t('Common.Time.Year', 'year')

  return timePeriod === 'daily'
    ? t('Common.PastX', { val: `1 ${month}` })
    : timePeriod === 'weekly'
    ? t('Common.PastX', { val: `6 ${months}` })
    : t('Common.PastX', { val: `1 ${year}` })
}

export interface GenericTabTrendViewProps {
  groupingKey: `${ComputeInstanceGroupingKeyKey}`
  tagKey?: string
  noLimit?: boolean
}

export const GenericTabTrendView = ({
  groupingKey,
  tagKey,
  noLimit,
}: GenericTabTrendViewProps) => {
  const { t } = useTranslation()

  const timeRangeOptions = useTimeRangeOptions()

  const [timeRange, setTimeRange] = useLocalStorage<TimePeriod>(
    `dashboard-grouping-chart-time-range-${{ groupingKey }}`,
    'daily',
  )
  const { startTimeNs, endTimeNs } = getStartAndEndTime(timeRange)
  const durationString = useDurationString(timeRange)

  const metricItems = useComputeInstanceSummariesMetricsDropdownItems()
  const {
    dropdownProps: { selectedItem: metric, ...metricDropdownProps },
  } = usePersistentDropdown(
    `dashboard-grouping-chart-summaries-metric-${groupingKey}`,
    metricItems,
  )

  const limitItems = [
    {
      id: '5',
      label: `${t('Workloads.TopCharts.Rollup.FilterItems.Top')} 5`,
    },
    {
      id: '10',
      label: `${t('Workloads.TopCharts.Rollup.FilterItems.Top')} 10`,
    },
    ...(noLimit === true
      ? [
          {
            id: '0',
            label: `${t('Workloads.TopCharts.Rollup.FilterItems.All')}`,
          },
        ]
      : []),
  ]

  const {
    dropdownProps: { selectedItem: limit, ...limitDropdownProps },
  } = usePersistentDropdown('dashboard-grouping-chart', limitItems)

  const orgOptionItems = [
    {
      id: 'OnlyDirectChildren',
      label: t('Workloads.TopCharts.Rollup.FilterItems.OnlyDirectChildren'),
    },
    {
      id: 'None',
      label: t('Workloads.TopCharts.Rollup.FilterItems.Normal'),
    },
  ]
  const {
    dropdownProps: { selectedItem: orgOption, ...orgOptionDropdownProps },
  } = usePersistentDropdown(
    'dashboard-grouping-chart-org-option',
    orgOptionItems,
  )

  const { data, isLoading, isError, error } = useInstanceSummariesRollupQuery({
    limit: Number(limit.id),
    summaryMetrics: metric.id as any,
    filter: {
      startTimeNs,
      endTimeNs,
      grouping: {
        groupingKey: {
          key: groupingKey as any,
          tagKey: tagKey as any,
        },
        duration: timeRange.toUpperCase() as SummaryInterval,
      },
    },
    ...(orgOption.id === 'OnlyDirectChildren' && {
      onlyDirectChildren: true,
    }),
  })

  const headers = useGroupingHeaders({
    metrics: computeInstanceSummaryMetricMap,
    overrideNameColumnHeader: tagKey,
  })

  const location = useLocation()
  const linkState = {
    pathname: location.pathname,
    search: location.search,
  }

  const rowsData = useMemo(() => {
    return data
      ? transformComputeInstanceGroupingData(groupingKey, data, linkState)
      : []
  }, [data])

  const formattedRows = useMemo(() => {
    return rowsData.map(summary => {
      const formatted = { ...summary }
      Object.values(computeInstanceSummaryMetricMap).forEach(metric => {
        formatted[`${metric}Formatted`] = createFormatFn(
          getFormatType(metric),
          {
            cpu: { shortDisplayValue: true },
          },
        )(summary[metric])
      })
      return formatted
    })
  }, [rowsData])

  const { rows, ...chartProps } = useDataTableLegend({
    data: formattedRows,
  })

  return (
    <>
      <Flex>
        <Box mt={5} mb={3}>
          <Flex gap="var(--cds-spacing-04)">
            <Dropdown
              id="grouping-chart-timeperiod"
              label="Time period"
              hideLabel
              selectedItem={timeRangeOptions.find(
                item => item.id === timeRange,
              )}
              onChange={({ selectedItem }) => {
                setTimeRange(selectedItem?.id as TimePeriod)
              }}
              items={timeRangeOptions}
            />
            <Dropdown
              label={t('Workloads.TopCharts.Rollup.Heading.Metric')}
              hideLabel
              selectedItem={metric}
              {...metricDropdownProps}
            />
            <Dropdown
              label={t('Workloads.TopCharts.Rollup.Heading.Limit')}
              hideLabel
              selectedItem={limit}
              {...limitDropdownProps}
            />
            {groupingKey === 'ORG' && (
              <Dropdown
                label={t('Workloads.TopCharts.Rollup.Heading.OrgOption')}
                hideLabel
                selectedItem={orgOption}
                {...orgOptionDropdownProps}
              />
            )}
          </Flex>
        </Box>
      </Flex>
      {isError ? (
        <InlineNotification
          title={`${t('Workloads.TopCharts.Rollup.LoadingError')} ${
            error?.data?.message
          }`}
          kind="error"
        />
      ) : (
        <>
          <CollapseComponent>
            <Box minHeight={300}>
              <GenericTabTrendViewChart
                data={data}
                isLoading={isLoading}
                duration={timeRange}
                summaryMetric={metric.id}
                {...chartProps}
              />
            </Box>
          </CollapseComponent>
          <Box mt={5}>
            <TabDataTable
              id={groupingKey}
              title={t('Common.Rollup', { timePeriod: durationString })}
              headers={headers}
              rows={rows}
              isLoading={isLoading}
            />
          </Box>
        </>
      )}
    </>
  )
}
