import dayjs from 'dayjs'
import { useCallback } from 'react'

import { TimeRangeFromPeriod } from 'src/next/utils/time'

export const useTooltipHeading = selectedTimeRangeID => {
  return useCallback(
    activePoint => {
      const timePeriod = {
        week: 'daily',
        month: 'daily',
        '6months': 'weekly',
      }[selectedTimeRangeID]
      const date = dayjs(activePoint?.x)
      return date.isValid() ? (
        <TimeRangeFromPeriod start={date} timePeriod={timePeriod} />
      ) : (
        ''
      )
    },
    [selectedTimeRangeID],
  )
}
