/* eslint-disable @typescript-eslint/ban-types */
import { useState } from 'react'
import { DialogClose } from '@radix-ui/react-dialog'
import { Button } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { UseMutationResult } from 'react-query'
import { Flex } from 'src/next/components/Flex'
import Loading from 'src/next/components/Loading'
import useToaster from 'src/next/hooks/useToaster'
import { Modal, ModalContent, ModalFooter } from './Modal'

export const useDeleteModal = () => {
  const [id, setId] = useState<string | null>(null)

  return { id, setId }
}

interface DeleteModalProps {
  id: ReturnType<typeof useDeleteModal>['id']
  setId: ReturnType<typeof useDeleteModal>['setId']
  title: string
  messages: {
    success: string
    error: string
  }
  query: () => UseMutationResult<{}, unknown, string, unknown>
}

export const DeleteModal = ({
  id,
  setId,
  title,
  query,
  messages,
}: DeleteModalProps) => {
  const { t } = useTranslation()
  const { addToast } = useToaster()

  const isOpen = !!id

  const deleteQuery = query()

  const deleteToast = (isSuccess: boolean) => {
    addToast({
      title: isSuccess ? messages.success : messages.error,
      kind: isSuccess ? 'success' : 'error',
    })
  }

  const resetId = () => {
    setId(null)
  }

  const handleDelete = async () => {
    if (!id) return

    deleteQuery.mutate(id, {
      onSuccess: () => {
        deleteToast(true)
        resetId()
      },
      onError: () => {
        deleteToast(false)
      },
    })
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      resetId()
    }
  }

  return (
    <Modal onOpenChange={handleOpenChange} open={isOpen}>
      <ModalContent size="xs" title={title}>
        {deleteQuery?.isLoading && <Loading withOverlay size="medium" />}{' '}
        <ModalFooter isButtonSet>
          <Flex flex={1}>
            <DialogClose asChild>
              <Button kind="secondary">{t('Common.Cancel')}</Button>
            </DialogClose>
            <Button kind="danger" onClick={handleDelete}>
              {t('Common.Delete')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
