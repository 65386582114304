import { zip } from 'lodash'
import {
  GetTopComputeInstanceSummariesResponse,
  GetTopSummariesResponse,
  Summary,
} from '@cloudnatix-types/dashboard'
import { GraphColor } from 'src/next/types/workloads'
import { unique } from 'src/next/utils/array'
import { getCarbonPaletteCssVariable } from 'src/next/utils/graph.utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'

export type ColorMap = { id: string; color: GraphColor }[]

export type TransformedData = (Summary & {
  timestamp: number
})[][]

export const getColorMapTopSummaries = (data: TransformedData) => {
  return unique(
    data
      .flat()
      .map(item => {
        return item.groupingName!
      })
      .filter(Boolean)
      .sort(),
  ).map((o, i) => {
    return {
      id: `${o}`,
      color: getCarbonPaletteCssVariable(i),
    }
  })
}

type TopSummariesResponse = GetTopSummariesResponse &
  GetTopComputeInstanceSummariesResponse

export function getTransformTopSummariesData(data: TopSummariesResponse) {
  const result = data.topSummaries
    ? Object.values(data.topSummaries)
        .filter(item => !!item.summaries)
        // transform object + timestamp to milliseconds
        .map(({ summaries }) =>
          summaries?.map(summary => ({
            ...summary,
            timestamp: nanoToMilliSeconds(summary.timestampNs || 0),
          })),
        )
    : []

  // The stacked bar chart expects a data structure different from the API
  // returns (namely, different X-values combined, instead of the same).
  // `zip` accounts for this and regroups the passed arrays:
  // https://lodash.com/docs/#zip
  //
  // after doing so, we filter undefined values, which can arise when f.e.
  // organizations were added later.
  return zip(...result).map(item => item.filter(Boolean)) as TransformedData
}
