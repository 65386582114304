import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateOptimizationPlan } from 'src/api'
import useToaster from 'src/next/hooks/useToaster'

interface handleDismissProp {
  uuid: string
  instanceID: string
  postModification: () => void
}

export const useDismiss = ({
  uuid,
  instanceID,
  postModification,
}: handleDismissProp) => {
  const { t } = useTranslation()

  const { mutate: updateOptimizationPlan } = useUpdateOptimizationPlan(uuid)

  const { addToast } = useToaster()

  const handleDismiss = useCallback(
    () =>
      updateOptimizationPlan(
        {
          uuid,
          isDismissed: true,
        },
        {
          onSuccess: () => {
            addToast({
              title: t(
                'PlannerCard.Action.dismiss_success_title',
                'Dismissed the plan',
              ),
              subtitle: t(
                'PlannerCard.Action.dismiss_success_subtitle',
                'Instance ID: {{instanceID}}',
                {
                  instanceID,
                },
              ),
              kind: 'success',
            })

            postModification()
          },
          onError: error => {
            addToast({
              title: t(
                'PlannerCard.Action.dismiss_error_title',
                'Failed to dismiss the plan',
              ),
              subtitle: t(
                'PlannerCard.Action.dismiss_error_subtitle',
                'Instance ID: {{instanceID}}, Error: {{error}}',
                {
                  instanceID,
                  error: error?.data?.message || '',
                },
              ),
              kind: 'error',
            })

            postModification()
          },
        },
      ),
    [updateOptimizationPlan, uuid, instanceID, postModification, addToast, t],
  )

  const handleUndismiss = useCallback(
    () =>
      updateOptimizationPlan(
        {
          uuid,
          isDismissed: false,
        },
        {
          onSuccess: () => {
            addToast({
              title: t(
                'PlannerCard.Action.undismiss_success_title',
                'Undismissed the plan',
              ),
              subtitle: t(
                'PlannerCard.Action.undismiss_success_subtitle',
                'Instance ID: {{instanceID}}',
                {
                  instanceID,
                },
              ),
              kind: 'success',
            })

            postModification()
          },
          onError: error => {
            addToast({
              title: t(
                'PlannerCard.Action.undismiss_error_title',
                'Failed to undismiss the plan',
              ),
              subtitle: t(
                'PlannerCard.Action.undismiss_error_subtitle',
                'Instance ID: {{instanceID}}, Error: {{error}}',
                {
                  instanceID,
                  error: error?.data?.message || '',
                },
              ),
              kind: 'error',
            })

            postModification()
          },
        },
      ),
    [updateOptimizationPlan, uuid, instanceID, postModification, addToast, t],
  )

  return {
    handleDismiss,
    handleUndismiss,
  }
}
