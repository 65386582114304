import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ManagementResource } from '@cloudnatix-types/dashboard'
import { Box, DataRow, Flex } from 'src/next/components'
import { TableExpandedPanel } from 'src/next/components/TableExpanded'
import LabelPopover from 'src/next/components/Workloads/WorkloadsTable/components/WorkloadLabelPopover'
import {
  bytesToUserFriendlySize,
  currencyFormat,
  millicoresToUserFriendlySizeLong,
} from 'src/next/utils'
import { ResourceType } from './ResourceType'

export interface ResourcesTableExpandedPanelProps {
  resource: ManagementResource
}

const ResourcesTableExpandedPanel = ({
  resource,
}: ResourcesTableExpandedPanelProps) => {
  const { t } = useTranslation()

  const renderedLabels = useMemo(() => {
    if (!resource.labels) {
      return '---'
    }

    const labels = resource.labels
    return labels.map(({ key, value }) => (
      <Box key={key}>
        <LabelPopover labelKey={key || ''} value={value} elide={false} />
      </Box>
    ))
  }, [resource.labels])

  return (
    <TableExpandedPanel>
      <Flex gap="var(--cds-spacing-05)" flexWrap="wrap">
        <Box flex="1">
          <DataRow label={t('Organizations.DataTable.Name')}>
            {resource.name}
          </DataRow>
          <DataRow label={t('Organizations.Type')}>
            <ResourceType type={resource.type} />
          </DataRow>
          <DataRow label={t('Resources.OrgOwner')}>{resource.orgName}</DataRow>
          <DataRow label={t('Organizations.DataTable.Csp')}>
            {resource.csp}
          </DataRow>
          <DataRow label={t('Common.Region')}>{resource.region}</DataRow>
          <DataRow label={t('Resources.ResourceClass')}>
            {resource.resourceClass}
          </DataRow>
          <DataRow label={t('Resources.ResourceOwnerName')}>
            {resource.resourceOwnerName}
          </DataRow>
          <DataRow label={t('WorkloadTable.Header.labels')}>
            <div>{renderedLabels}</div>
          </DataRow>
          <DataRow label={t('Common.Spend')}>
            {t('Common.Value/hr', {
              value: currencyFormat(resource.spend || 0),
            })}
          </DataRow>
          <DataRow label={t('Common.CPU')}>
            {millicoresToUserFriendlySizeLong(
              Number(resource.resourceCapacity?.cpuMillicores) || 0,
            )}
          </DataRow>
          <DataRow label={t('Common.Memory')}>
            {bytesToUserFriendlySize(
              resource.resourceCapacity?.memoryBytes || 0,
            )}
          </DataRow>
          <DataRow label={t('Common.Disk')}>
            {bytesToUserFriendlySize(resource.resourceCapacity?.diskBytes || 0)}
          </DataRow>
        </Box>
      </Flex>
    </TableExpandedPanel>
  )
}

export default ResourcesTableExpandedPanel
