import { Flex } from 'src/next/components'
import {
  AuditLogFilters,
  AuditLogTable,
} from 'src/next/components/administration'
import { TableFilterContextProvider } from 'src/next/components/TableFilter'

export const AuditLogPage = () => {
  return (
    <TableFilterContextProvider localStorageId="audit-log-filters">
      <Flex>
        <AuditLogFilters />
        <AuditLogTable />
      </Flex>
    </TableFilterContextProvider>
  )
}
