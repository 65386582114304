import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import { Box } from 'src/next/components'
import { ContentSwitcher, Switch } from 'src/next/components/ContentSwitcher'
import { GenericTabTrendView } from './charts'
import { GenericTabSummaryView } from './GenericTabSummaryView'
import { TagKeySelect } from './TagKeySelect'

interface GenericTabProps {
  group: `${ComputeInstanceGroupingKeyKey}`
  withTags?: boolean
  noLimit?: boolean
}

export const GenericTab = ({ group, withTags, noLimit }: GenericTabProps) => {
  const { t } = useTranslation()

  const chartTypes = [
    {
      label: t('Dashboard.Tabs.SelectDetailed'),
      id: 'detailed',
    },
    {
      label: t('Dashboard.Tabs.SelectSummary'),
      id: 'summary',
    },
  ] as const

  const initiallySelectedChartTypeIndex = 0
  const [selectedChartTypeIndex, setSelectedChartTypeIndex] = useState<number>(
    initiallySelectedChartTypeIndex,
  )
  const selectedChartType = chartTypes[selectedChartTypeIndex].id

  const [tagKey, setTagKey] = useState<string | undefined>(undefined)

  return (
    <div>
      {withTags ? (
        <Box mb={5} minWidth="350px" maxWidth="400px">
          <TagKeySelect setTagKey={setTagKey} />
        </Box>
      ) : null}

      <ContentSwitcher
        onChange={({ index }) => {
          if (index === undefined) return
          setSelectedChartTypeIndex(index)
        }}
        selectedIndex={initiallySelectedChartTypeIndex}
        size="md"
        data-testid="chart-type-selector"
      >
        {chartTypes.map(({ id, label }) => (
          <Switch name={id} text={label} key={id} />
        ))}
      </ContentSwitcher>

      {selectedChartType === 'detailed' ? (
        <GenericTabTrendView
          groupingKey={group}
          tagKey={tagKey}
          noLimit={noLimit}
        />
      ) : selectedChartType === 'summary' ? (
        <GenericTabSummaryView group={group} tagKey={tagKey} />
      ) : null}
    </div>
  )
}
