import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DialogClose } from '@radix-ui/react-dialog'
import { Button } from '@carbon/react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Org } from '@cloudnatix-types/orgcontroller'
import { useCreateOrg, useUpdateOrg } from 'src/api'
import { Flex } from 'src/next/components'
import { InlineNotification } from 'src/next/components/InlineNotification'
import Loading from 'src/next/components/Loading'
import { Modal, ModalContent, ModalFooter } from 'src/next/components/Modal'
import useToaster from 'src/next/hooks/useToaster'
import { removeFocusFromButton } from 'src/next/utils'
import { OrgForm } from './OrgForm'

export const useOrgActionsModal = () => {
  const [open, setOpen] = useState(false)
  const [org, setOrg] = useState<Org | null>(null)

  useEffect(() => {
    if (!open) setOrg(null)
  }, [setOrg, open])

  return { org, setOrg, open, setOpen }
}
export interface OrgFormValues {
  name: string
  description: string
  parentOrgUuid: string
}

interface OrgActionsModalProps {
  org: Org | null
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const OrgActionsModal = ({
  org,
  open,
  setOpen,
}: OrgActionsModalProps) => {
  const { t } = useTranslation()
  const { addToast } = useToaster()

  const onClose = () => {
    setOpen(false)
    removeFocusFromButton()
  }

  const isEditMode = !!org

  const {
    mutate: createOrg,
    isError: isErrorCreate,
    isLoading: isLoadingCreate,
  } = useCreateOrg()
  const {
    mutate: updateOrg,
    isError: isErrorUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateOrg(org?.uuid!)

  const handleSuccess = () => {
    addToast({
      title: isEditMode
        ? t('Organizations.Update.Success')
        : t('Organizations.Create.Success'),
      kind: 'success',
    })
    onClose()
  }

  const onSubmit = async (newOrg: OrgFormValues) => {
    if (org) {
      updateOrg(
        {
          uuid: org.uuid!,
          name: newOrg.name,
          description: newOrg.description,
          parentOrgUuid: newOrg.parentOrgUuid,
        },
        {
          onSuccess: handleSuccess,
        },
      )
    } else {
      createOrg(newOrg, {
        onSuccess: handleSuccess,
      })
    }
  }

  const defaultValues = isEditMode
    ? org
    : {
        name: '',
        description: '',
        parentOrgUuid: '',
      }

  const formMethods = useForm<OrgFormValues>({
    defaultValues,
  })

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen)
  }

  return (
    <Modal onOpenChange={handleOpenChange} open={open}>
      <ModalContent
        title={
          isEditMode ? t('Organizations.EditOrg') : t('Organizations.AddOrg')
        }
      >
        {isLoadingCreate || isLoadingUpdate ? (
          <Loading withOverlay size="medium" />
        ) : null}

        {isErrorCreate || isErrorUpdate ? (
          <InlineNotification
            title={
              isEditMode
                ? t('Organizations.Update.Error')
                : t('Organizations.Create.Error')
            }
            kind="error"
          />
        ) : null}
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            id="org-action-form"
          >
            <OrgForm />
          </form>
        </FormProvider>
        <ModalFooter isButtonSet>
          <Flex flex={1}>
            <DialogClose asChild>
              <Button kind="secondary">{t('Common.Cancel')}</Button>
            </DialogClose>
            <Button kind="primary" type="submit" form="org-action-form">
              {isEditMode ? t('Common.Update') : t('Common.Create')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
