import { useTranslation } from 'react-i18next'
import { useSubscriptionStatus, useUserSelf } from 'src/api'
import * as Banners from './Banners'

const sortOrder = [
  'SUBSCRIPTION_STATUS_WARNING_TYPE_OVER_LIMIT',
  'SUBSCRIPTION_STATUS_WARNING_TYPE_NEAR_LIMIT',
]

export type SubscriptionBannerTypes = 'CORE' | 'USER' | 'ORG'

export const useSubscriptionBanner = (type: SubscriptionBannerTypes) => {
  const { t } = useTranslation()
  const { data: user } = useUserSelf()

  const isFreemium =
    user?.tenant?.subscriptionProperties?.offeringType === 'FREEMIUM'

  const { data: status } = useSubscriptionStatus(
    {},
    {
      enabled: !!user && isFreemium,
      staleTime: 60 * 60 * 1000, // 1 hour
      select: data =>
        data.limitConditions
          // We only work with warning types defined in sortOrder
          ?.filter(condition => sortOrder.includes(condition.warningType!))
          // Sort the most important warning type first. In case we receive
          // multiple statuses we should show 'over limit' instead of 'near limit'
          .sort(
            (a: any, b: any) =>
              sortOrder.indexOf(a.warningType) -
              sortOrder.indexOf(b.warningType),
          ),
    },
  )

  // Get limits for selected type
  const limits = status?.filter(
    condition =>
      condition.limitType === `SUBSCRIPTION_STATUS_LIMIT_TYPE_${type}`,
  )

  // Get the right banner (only one, error or warning)
  const getBanner = () => {
    const limit = limits && limits[0]
    if (!limit) {
      return null
    }

    const units = {
      SUBSCRIPTION_STATUS_LIMIT_TYPE_CORE: t('SubscriptionStatus.Units.Core'),
      SUBSCRIPTION_STATUS_LIMIT_TYPE_ORG: t('SubscriptionStatus.Units.Org'),
      SUBSCRIPTION_STATUS_LIMIT_TYPE_USER: t('SubscriptionStatus.Units.User'),
      SUBSCRIPTION_STATUS_LIMIT_TYPE_TIME_RANGE: t(
        'SubscriptionStatus.Units.TimeRange',
      ),
    } as { [key: string]: string }

    if (limit.warningType === 'SUBSCRIPTION_STATUS_WARNING_TYPE_OVER_LIMIT') {
      return (
        <Banners.LimitReached
          used={limit.used!}
          total={limit.limit!}
          unit={units[limit.limitType!] || ''}
        />
      )
    }

    return (
      <Banners.LimitWarning
        used={limit.used!}
        total={limit.limit!}
        unit={units[limit.limitType!]}
      />
    )
  }

  return {
    Banner: getBanner,
  }
}
