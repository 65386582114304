import React from 'react'
import { OrganizationsTable } from 'src/next/components/administration'
import OrganizationsTableFilters from 'src/next/components/administration/Organizations/Table/OrganizationsTableFilter'
import { SubscriptionBanner } from 'src/next/components/SubscriptionBanner'
import {
  TableFilterContainer,
  TableFilterContextProvider,
} from 'src/next/components/TableFilter'

export const OrganizationsPage = () => {
  return (
    <>
      <SubscriptionBanner type="ORG" />
      <TableFilterContextProvider
        localStorageId="org-page-filters"
        defaultValues={{}}
      >
        <TableFilterContainer data-testid="org-table-filters">
          <OrganizationsTableFilters />
          <OrganizationsTable />
        </TableFilterContainer>
      </TableFilterContextProvider>
    </>
  )
}
