import { useContext } from 'react'
import ToasterContext from 'src/next/contexts/ToasterContext'

const useToaster = () => {
  const context = useContext(ToasterContext)

  if (context === undefined) {
    throw new Error('useToaster must be used within <ToasterContextProvider>')
  }

  return context
}

export default useToaster
