import { useState } from 'react'
import {
  flip,
  offset,
  shift,
  useClientPoint,
  useInteractions,
  useFloating,
} from '@floating-ui/react'

const useGraphTooltip = () => {
  const { context, x, y, isPositioned, floatingStyles, refs } = useFloating({
    placement: 'right',
    strategy: 'absolute',
    middleware: [
      offset({ mainAxis: 10, crossAxis: 35 }),
      flip({ padding: 50 }),
      shift(),
    ],
  })

  const clientPoint = useClientPoint(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([clientPoint])

  return {
    // Manually check `x` and `y` to catch the case `isPositioned` doesn't cover.
    isPositioned: isPositioned && x >= 0 && y >= 0,
    setReference: refs.setReference,
    setFloating: refs.setFloating,
    getReferenceProps,
    getFloatingProps,
    floatingStyles,
  }
}

export default useGraphTooltip
