import React, { Dispatch, SetStateAction, useState } from 'react'
import { DialogClose } from '@radix-ui/react-dialog'
import { Button } from '@carbon/react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ConversionRule,
  CreateConversionRuleResponse,
  LabelFilter,
  UpdatedResourceOwner,
} from '@cloudnatix-types/orgcontroller'
import { useCreateConversionRule, useUpdateConversionRule } from 'src/api'
import { queryClient } from 'src/api/queryClient'
import { Box, Flex } from 'src/next/components'
import DataTable from 'src/next/components/DataTable'
import { InlineNotification } from 'src/next/components/InlineNotification'
import Loading from 'src/next/components/Loading'
import { Modal, ModalContent, ModalFooter } from 'src/next/components/Modal'
import { useTableFilter } from 'src/next/components/TableFilter'
import useToaster from 'src/next/hooks/useToaster'
import { removeFocusFromButton } from 'src/next/utils'
import { ResourcesAllocateForm } from './ResourcesAllocateForm'

export const useResourceAllocateModal = () => {
  const [open, setOpen] = useState(false)
  const [rule, setRule] = useState<ConversionRule | null>(null)

  return { rule, setRule, open, setOpen }
}

const filterTypes = ['clusterName', 'csp', 'region']

export interface AllocateFormValues {
  name: string
  orgUuid: string
  labelFilters: LabelFilter[]
}

interface ResourceAllocateModalProps {
  rule: ConversionRule | null
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const ResourceAllocateModal = ({
  rule,
  open,
  setOpen,
}: ResourceAllocateModalProps) => {
  const { t } = useTranslation()
  const { addToast } = useToaster()

  const [resources, setResources] = useState<UpdatedResourceOwner[]>([])

  const isEditMode = !!rule

  const {
    mutate: createConversionRule,
    error: errorCreate,
    isLoading: isLoadingCreate,
  } = useCreateConversionRule()
  const {
    mutate: updateConversionRule,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateConversionRule(rule?.uuid!)

  const error = errorCreate || errorUpdate
  // @ts-ignore
  const errorMsg = error?.data?.message
  const isLoading = isLoadingCreate || isLoadingUpdate

  // convert table filters to label filters
  const { filters } = useTableFilter()
  const filterEntries = Object.entries(filters)
  const labelFilters = filterEntries.length
    ? filterEntries
        .filter(([key, value]) => filterTypes.includes(key) && !!value)
        .map(([key, value]) => {
          return {
            key: key === 'clusterName' ? 'cluster-name' : key,
            value: Array.isArray(value) ? value[0] : value,
          }
        })
    : []

  const onClose = () => {
    setOpen(false)
    removeFocusFromButton()
  }

  const handleSuccess = (data: CreateConversionRuleResponse, dryrun = true) => {
    if (dryrun && data?.updatedResourceOwners) {
      setResources(data.updatedResourceOwners)
    } else {
      addToast({
        title: isEditMode
          ? t('Resources.Allocation.Update.Success')
          : t('Resources.Allocation.Create.Success'),
        kind: 'success',
      })
      onClose()
      queryClient.invalidateQueries('v1/conversion-rules')
    }
  }

  const update = (newRule: AllocateFormValues, dryrun = true) => {
    updateConversionRule(
      {
        dryrun,
        uuid: rule!.uuid!,
        name: newRule.name,
        labelFilters: newRule.labelFilters,
      },
      {
        onSuccess(data) {
          handleSuccess(data, dryrun)
        },
      },
    )
  }

  const create = (newRule: AllocateFormValues, dryrun = true) => {
    createConversionRule(
      {
        dryrun,
        action: { orgUuid: newRule.orgUuid },
        labelFilters: newRule.labelFilters,
        name: newRule.name,
      },
      {
        onSuccess(data) {
          handleSuccess(data, dryrun)
        },
      },
    )
  }

  const onSubmitDryRun = async (newRule: AllocateFormValues) => {
    if (rule) {
      update(newRule, true)
    } else {
      create(newRule, true)
    }
  }

  const onSubmit = async (newRule: AllocateFormValues) => {
    if (rule) {
      update(newRule, false)
    } else {
      create(newRule, false)
    }
  }

  const defaultValues = isEditMode
    ? {
        name: rule.name,
        labelFilters: rule?.labelFilters || [],
        orgUuid: rule?.action?.orgUuid,
      }
    : {
        name: '',
        orgUuid: '',
        labelFilters,
      }

  const formMethods = useForm<AllocateFormValues>({
    mode: 'all',
    defaultValues,
  })

  return (
    <Modal onOpenChange={setOpen} open={open}>
      <ModalContent
        title={
          isEditMode
            ? t('Resources.Allocation.Edit')
            : t('Resources.Allocation.Add')
        }
      >
        {isLoading ? <Loading withOverlay size="medium" /> : null}

        {error ? (
          <InlineNotification
            title={
              errorMsg ||
              (isEditMode
                ? t('Resources.Allocation.Update.Error')
                : t('Resources.Allocation.Create.Error'))
            }
            kind="error"
          />
        ) : null}
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            id="resources-allocate-form"
          >
            <ResourcesAllocateForm isEditMode={isEditMode} />
          </form>
        </FormProvider>
        <br />
        {!isEditMode ? (
          <Button
            size="sm"
            kind="secondary"
            onClick={() => {
              formMethods.handleSubmit(onSubmitDryRun)()
            }}
            form="resources-allocate-form"
            disabled={!formMethods.formState.isValid}
          >
            {t('Resources.Allocation.DryRun')}
          </Button>
        ) : null}

        <Box my="6">
          {!isLoading ? (
            <ResourcesList
              resources={resources}
              isSubmitted={formMethods.formState.isSubmitted}
            />
          ) : null}
        </Box>
        <ModalFooter isButtonSet>
          <Flex flex={1}>
            <DialogClose asChild>
              <Button kind="secondary">{t('Common.Cancel')}</Button>
            </DialogClose>
            <Button
              type="submit"
              form="resources-allocate-form"
              disabled={!formMethods.formState.isValid}
            >
              {isEditMode ? t('Common.Update') : t('Common.Create')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const ResourcesList = ({
  resources,
  isSubmitted = false,
}: {
  resources: UpdatedResourceOwner[]
  isSubmitted: boolean
}) => {
  const { t } = useTranslation()
  const rows = resources?.map(resource => ({
    ...resource,
    id: resource.resourceId!,
  }))

  const headers = [
    {
      key: 'resourceName',
      header: t('Resources.Allocation.Resource'),
    },
    { key: 'oldOrgName', header: t('Resources.Allocation.Before') },
    { key: 'newOrgName', header: t('Resources.Allocation.After') },
  ]

  if (isSubmitted && !rows?.length) {
    return (
      <InlineNotification
        title={t('Resources.Allocation.NoMatch')}
        kind="info"
      />
    )
  }

  return (
    <>
      {resources.length > 0 ? (
        <DataTable
          storageKey="admin-resources-allocate-list"
          showToolbar={false}
          headers={headers}
          rows={rows}
        />
      ) : null}
    </>
  )
}
