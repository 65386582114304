import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ComputeInstanceSummary } from '@cloudnatix-types/dashboard'
import { useCurrentOrgInstanceSummaries } from 'src/api'
import { LineGraphProps } from 'src/next/components/Graphs'
import { Graph } from 'src/next/components/Graphs/Graph'
import GraphTooltip from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import Loading from 'src/next/components/Loading'
import { millicoresToUserFriendlySizeLong } from 'src/next/utils'
import { buildDayTicks } from 'src/next/utils/graph.utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'

import { MetricContainer } from './MetricContainer'
import { useTooltipHeading } from './useTooltipHeading'

export const GpuMetrics = () => {
  const { t } = useTranslation()

  const id = 'dashboard-metrics-gpu'

  const {
    query: { data, isLoading },
    selectedTimeRange,
  } = useCurrentOrgInstanceSummaries(id)

  const capacityDataPoints = useMemo(
    () =>
      data?.summaries?.map((summary: ComputeInstanceSummary) => ({
        x: new Date(nanoToMilliSeconds(summary.timestampNs!)),
        y: summary.avgGpuCapacity!,
      })) || [],
    [data],
  )

  // The range x may take is the same for the used and unused series. Use either of them to build tick values.
  const xTickValues = useMemo(
    () => buildDayTicks(capacityDataPoints),
    [capacityDataPoints],
  )

  const graphConfig = [
    {
      id: 'avg_gpu_capacity',
      label: t('ComputeInstanceSummary.avg_gpu_capacity', 'Avg. GPU capacity'),
      tooltipLabel: t('ComputeInstanceSummary.avg_gpu_capacity'),
      tooltipValueTransformFn: (value: unknown) =>
        millicoresToUserFriendlySizeLong(Number(value), 2),
      enabled: true,

      type: 'line' as const,
      data: capacityDataPoints,
      props: {
        style: { data: { stroke: 'var(--carbonPalette4)' } },
      },
    } as LineGraphProps,
  ]

  const getTooltipHeading = useTooltipHeading(selectedTimeRange.id)

  return (
    <MetricContainer id={id} title={t('Common.GPU')}>
      {isLoading ? (
        <Loading centered size="small" withOverlay={false} />
      ) : (
        <>
          <Graph
            data={graphConfig}
            unit="cores"
            height={200}
            xAxis={[
              {
                tickFormat: (x: any) => dayjs(x).format('MM/DD'),
                tickValues: xTickValues,
              },
            ]}
            yAxis={[{}]}
            containerComponent={GraphTooltipContainer(
              <GraphTooltip
                graphConfig={graphConfig}
                heading={getTooltipHeading}
              />,
            )}
          />
        </>
      )}
    </MetricContainer>
  )
}
