import { useEffect, useState } from 'react'
import {
  Button,
  DismissibleTag,
  OverflowMenu,
  OverflowMenuItem,
  Tag,
} from '@carbon/react'
/* eslint-disable react/no-unknown-property */
import { Close, PiggyBank, Unknown } from '@carbon/react/icons'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { css } from 'styled-components'
import {
  ComputeInstanceOptimizationPlan,
  ComputeInstanceConsumer,
} from '@cloudnatix-types/dashboard'
import { Box, Link, Typography } from 'src/next/components'
import { LoadingWrapper } from 'src/next/components/LoadingWrapper'
import Text from 'src/next/components/Text'
import { useDismiss } from 'src/next/hooks/useDismiss'
import { currencyFormat } from 'src/next/utils'
import { ReactComponent as VMIcon } from '../../../../images/VM.svg'
import { Container, Left, Right } from './InsightsCard'
import { useComputeInstanceOptimization } from 'src/api/queries/useComputeInstanceOptimizations'
import { useTableFilterSearchSelection } from 'src/next/components/TableFilter/TableFilterSearchSelection/TableFilterSearchSelection'

export interface ComputeInstanceInsightsCardProps {
  plan: ComputeInstanceOptimizationPlan
}

export const ComputeInstanceInsightsCard = ({
  plan: propPlan,
}: ComputeInstanceInsightsCardProps) => {
  const { t } = useTranslation()

  const addAutoScalingGroupNameFilter = useTableFilterSearchSelection({
    id: 'autoScalingGroupNames',
  })

  const uuid = propPlan.uuid!

  const [
    shouldUseUseComputeInstanceOptimization,
    setShouldUseUseComputeInstanceOptimization,
  ] = useState(false)

  const location = useLocation()

  const { data, refetch, isFetching } = useComputeInstanceOptimization(
    {
      uuid: uuid,
    },
    {
      enabled: false,
    },
  )

  useEffect(() => {
    // For simplicity, once the source is changed to `useComputeInstanceOptimization`, keep using it but refetch on prop change.
    if (shouldUseUseComputeInstanceOptimization) {
      refetch()
    }
  }, [propPlan])

  const plan =
    shouldUseUseComputeInstanceOptimization && data ? data.plan! : propPlan

  const recommendationLink = `/app/vm-workload/${encodeURIComponent(
    plan.instanceId!,
  )}?tabs=recommendations`

  const description = t('PlannerCard.compute_instance_description', {
    from: plan.originalInstanceType,
    to: plan.optimizedInstanceType,
  })

  const accountIDNameForCSP =
    plan.csp === 'aws'
      ? t('Common.AWSAccountID')
      : plan.csp === 'azure'
      ? t('Common.AzureSubscriptionID')
      : plan.csp === 'gcp'
      ? t('Common.GCPProjectID')
      : plan.csp === 'oci'
      ? t('Common.OCICompartmentOCID')
      : ''

  const consumer = plan.consumer
  const consumerTag =
    consumer == ComputeInstanceConsumer.AWS_ECS
      ? t('Common.AWS_ECS')
      : consumer == ComputeInstanceConsumer.AWS_EMR
      ? t('Common.AWS_EMR')
      : consumer == ComputeInstanceConsumer.GCP_DATAFLOW
      ? t('Common.GCP_DATAFLOW')
      : null

  const color = plan.isDismissed
    ? 'var(--cds-text-placeholder)'
    : 'var(--cds-text-primary)'

  const postModification = () => {
    setShouldUseUseComputeInstanceOptimization(true)
    refetch()
  }

  const { handleDismiss, handleUndismiss } = useDismiss({
    uuid,
    instanceID: plan.instanceId!,
    postModification,
  })

  const linkState = {
    pathname: location.pathname,
    search: location.search,
  }

  return (
    <LoadingWrapper isLoading={isFetching} height="100%">
      <Container>
        <Box top={0} right={0} position="absolute">
          {plan.usingCpuUtilization ? null : (
            <Tag renderIcon={Unknown} type="red">
              {t('PlannerCard.cpu_utilization_data_missing')}
            </Tag>
          )}
          {plan.usingMemoryUtilization ? null : (
            <Tag renderIcon={Unknown} type="red">
              {t('PlannerCard.memory_utilization_data_missing')}
            </Tag>
          )}
          {consumerTag ? <Tag type="purple">{consumerTag}</Tag> : null}
          {plan.isDismissed ? (
            plan.canDismiss ? (
              <DismissibleTag
                onClose={handleUndismiss}
                title={t('Common.Undismiss') || ''}
              >
                {t('Common.Dismissed')}
              </DismissibleTag>
            ) : (
              <Tag>{t('Common.Dismissed')}</Tag>
            )
          ) : plan.canDismiss ? (
            <Button
              hasIconOnly
              renderIcon={Close}
              kind="ghost"
              size="sm"
              iconDescription={t('Common.Dismiss')}
              tooltipPosition="bottom"
              onClick={handleDismiss}
            ></Button>
          ) : null}
        </Box>
        <Left>
          <Text icon={<PiggyBank color={color} />}>
            <Typography
              variant="productive-heading-03"
              style={{ lineHeight: 1.1, marginTop: '-2px' }}
              color={color}
            >
              {t('PlannerCard.title')}
            </Typography>
          </Text>
          <Text icon={<VMIcon width={32} color={color} />}>
            <Typography
              variant="productive-heading-02"
              style={{ lineHeight: 1.1 }}
              color={color}
            >
              {plan.instanceName}
            </Typography>
          </Text>
          <Text>
            <Typography variant="body-short" color={color}>
              {`${t('Common.Id')}: ${plan.instanceId}`}
            </Typography>
          </Text>
          {plan.autoScalingGroupName ? (
            <div>
              <Text>
                <Typography variant="body-short" color={color}>
                  {`${t('Common.AutoScalingGroup')}: ${
                    plan.autoScalingGroupName
                  }`}
                  <Box
                    verticalAlign="middle"
                    display="inline"
                    ml="var(--cds-spacing-01)"
                  >
                    <OverflowMenu
                      size="sm"
                      aria-label={t('Common.Action', 'Action')}
                      iconDescription={t('Common.Action', 'Action')}
                    >
                      <OverflowMenuItem
                        itemText={
                          t('PlannerCard.filter_by_this', 'Filter by this') ||
                          ''
                        }
                        onClick={() =>
                          addAutoScalingGroupNameFilter(
                            plan.autoScalingGroupName,
                          )
                        }
                      />
                    </OverflowMenu>
                  </Box>
                </Typography>
              </Text>
            </div>
          ) : null}
          {accountIDNameForCSP ? (
            <Text>
              <Typography variant="body-short" color={color}>
                {`${accountIDNameForCSP}: ${plan.accountId}`}
              </Typography>
            </Text>
          ) : null}

          <Link to={recommendationLink} state={linkState}>
            {t('Common.Recommendation')}: {description}
          </Link>
        </Left>
        <Right>
          <Typography
            variant="productive-heading-03"
            css={css`
              font-weight: bold;
            `}
            color={color}
          >
            {t('PlannerCard.cost_saving')}
          </Typography>
          <div
            css={css`
              width: 100%;
              text-align: center;
              color: ${color};
            `}
          >
            <h3
              css={css`
                font-weight: bold;
              `}
            >
              {plan.hasConservativeResult
                ? t('PlannerCard.cost_saving_ranged_value', {
                    low: currencyFormat(
                      plan.conservativeProjectedSavingsPer30day,
                    ),
                    high: currencyFormat(plan.projectedSavingsPer30day! || 0),
                  })
                : currencyFormat(plan.projectedSavingsPer30day! || 0)}
            </h3>
          </div>
        </Right>
      </Container>
    </LoadingWrapper>
  )
}
