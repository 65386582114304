import React, { memo, ReactElement } from 'react'
import useResizeAware from 'react-resize-aware'
import styled from 'styled-components'

type Dimension = {
  width: number
  height: number
}

interface BoundingSizeProps {
  defaultWidth?: number
  defaultHeight?: number
  render: ({ width, height }: Dimension) => ReactElement | null
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

/**
 * BoundingSize will take up the width and height of the parent element and provides the width and height in pixels.
 *
 * @example
 * <BoundingSize render={
 *   ({ width, height }) => <div>This box is {width}x{height}px</div>
 * }>
 *
 * @example
 * <BoundingSize render={RenderDimensionsComponent}>
 */
const BoundingSize = ({
  defaultWidth = 0,
  defaultHeight = 0,
  render,
}: BoundingSizeProps) => {
  const [resizeListener, sizes] = useResizeAware()

  return (
    <Wrapper>
      {resizeListener}
      {render({
        width: sizes.width || defaultWidth,
        height: sizes.height || defaultHeight,
      })}
    </Wrapper>
  )
}

export default memo(BoundingSize)
