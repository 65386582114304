import { useTranslation } from 'react-i18next'
import {
  TableFilter,
  TableFilterAccordion,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterSearchSelection,
} from 'src/next/components/TableFilter'

export const OrganizationsTableFilters = () => {
  const { t } = useTranslation()

  return (
    <TableFilter>
      <TableFilterActiveItems />
      <div data-testid="org-filter-items">
        <TableFilterAccordion>
          {/* Names */}
          <TableFilterAccordionItem
            title={t('Organizations.DataTable.Filters.Names')}
          >
            <TableFilterSearchSelection
              id="orgName"
              placeholder={t(
                'Organizations.DataTable.Filters.NamesPlaceHolder',
              )}
            />
          </TableFilterAccordionItem>
        </TableFilterAccordion>
      </div>
    </TableFilter>
  )
}

export default OrganizationsTableFilters
