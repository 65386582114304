import React, { useMemo } from 'react'
import { GetTopComputeInstanceSummariesResponse } from '@cloudnatix-types/dashboard'
import { Box } from 'src/next/components'
import Loading from 'src/next/components/Loading'
import { computeInstanceSummaryMetricMap } from 'src/next/constants/summary'
import { StackedSummariesChart } from 'src/next/components/dashboard/tabs/charts/TopSummariesChart'
import {
  ColorMap,
  getTransformTopSummariesData,
} from 'src/next/components/dashboard/tabs/charts/TopSummariesUtils'
import { TabSummariesChartSettingMetric } from 'src/next/containers/WorkloadCharts/types'
import { TimePeriod } from 'src/next/utils/time'

export interface GenericTabTrendViewChartProps {
  data?: GetTopComputeInstanceSummariesResponse
  isLoading: boolean
  duration: TimePeriod
  summaryMetric: TabSummariesChartSettingMetric
  showLegend?: boolean
  multiLegendSelect?: boolean
  selectedId?: string | null
  colorMap: ColorMap
}

export const GenericTabTrendViewChart = ({
  data,
  isLoading,
  duration,
  summaryMetric,
  ...props
}: GenericTabTrendViewChartProps) => {
  const yAccessorKey = computeInstanceSummaryMetricMap[summaryMetric]

  // transform the data into a data structure the stacked bar chart can display
  const transformedData = useMemo(() => {
    return getTransformTopSummariesData(data || {})
  }, [data])

  if (isLoading) {
    return (
      <Box height="450px" position="relative">
        <Loading centered withOverlay={false} />
      </Box>
    )
  }

  if (!transformedData?.length) return null

  return (
    <StackedSummariesChart
      data={transformedData}
      timePeriod={duration}
      yAccessorKey={yAccessorKey}
      height={680}
      width={1616}
      padding={{ left: 60, top: 20, bottom: 50, right: 0 }}
      {...props}
    />
  )
}
