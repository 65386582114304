import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TabSummariesChartSettingMetric } from 'src/next/containers/WorkloadCharts/types'
import { TimePeriod } from 'src/next/utils/time'

export const useComputeInstanceSummariesMetricsDropdownItems = (): (Record<
  'id',
  TabSummariesChartSettingMetric
> & {
  label: string
})[] => {
  const { t } = useTranslation()

  return [
    { id: 'TOTAL_SPEND', label: t('Resources.TotalSpend') },
    { id: 'WASTED_SPEND', label: t('Resources.WastedSpend') },
    { id: 'AVG_CPU_USAGE', label: t('Resources.AvgCpuUsage') },
    { id: 'MAX_CPU_USAGE', label: t('Resources.MaxCpuUsage') },
    { id: 'CI_AVG_CPU_CAPACITY', label: t('Resources.AvgCpuCapacity') },
    { id: 'CI_MAX_CPU_CAPACITY', label: t('Resources.MaxCpuCapacity') },
    { id: 'AVG_MEMORY_USAGE', label: t('Resources.AvgMemoryUsage') },
    { id: 'MAX_MEMORY_USAGE', label: t('Resources.MaxMemoryUsage') },
    { id: 'CI_AVG_MEMORY_CAPACITY', label: t('Resources.AvgMemoryCapacity') },
    { id: 'CI_MAX_MEMORY_CAPACITY', label: t('Resources.MaxMemoryCapacity') },
    { id: 'CI_AVG_DISK_CAPACITY', label: t('Resources.CiAvgDiskCapacity') },
    { id: 'CI_MAX_DISK_CAPACITY', label: t('Resources.CiMaxDiskCapacity') },
  ]
}

export const useTimeRangeOptions = (): (Record<'id', TimePeriod> & {
  label: string
})[] => {
  const { t } = useTranslation()
  return useMemo(
    () => [
      {
        id: 'daily',
        label: t('Workloads.TopCharts.Filters.DailyRollup'),
      },
      {
        id: 'weekly',
        label: t('Workloads.TopCharts.Filters.WeeklyRollup'),
      },
      {
        id: 'monthly',
        label: t('Workloads.TopCharts.Filters.MonthlyRollup'),
      },
    ],
    [t],
  )
}
