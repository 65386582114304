import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '@carbon/react'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import { useDashboardInstanceGroups } from 'src/api'
import { Box, Flex } from 'src/next/components'
import { CollapseComponent } from 'src/next/components/ui'
import { useTimeRangeOverflowMenu } from '../timerange'
import { GenericTabSummaryViewChart } from './charts'
import { TabDataTable } from './tables'
import { useFormatRows, useHeaders } from './tables/useSummaryTable'

interface GenericTabSummaryViewProps {
  group: `${ComputeInstanceGroupingKeyKey}`
  tagKey?: string
}

export const GenericTabSummaryView = ({
  group,
  tagKey,
}: GenericTabSummaryViewProps) => {
  const { t } = useTranslation()

  const { TimeRangeMenu, items, selectedItem, startTimeNs, endTimeNs } =
    useTimeRangeOverflowMenu({
      id: `dashboard-tab-${group}`,
      show24HoursOption: true,
    })

  const { isLoading, data } = useDashboardInstanceGroups({
    groupingKey: {
      key: group as any,
      tagKey,
    },
    filter: {
      startTimeNs,
      endTimeNs,
    },
  })

  const groupingValues = useMemo(() => {
    return data?.map(({ name, uid }) => ({
      name: name!,
      uid: uid!,
    }))
  }, [data])

  const [selectedGroupingValue, setSelectedGroupingValue] = useState(
    groupingValues?.[0],
  )

  useEffect(() => {
    setSelectedGroupingValue(groupingValues?.[0])
  }, [groupingValues])

  const headers = useHeaders({
    overrideNameColumnHeader: tagKey,
  })

  const rows = useFormatRows(data)

  const numInstanceGroups = data?.length
  const hasData = numInstanceGroups !== undefined && numInstanceGroups > 0

  return (
    <>
      <CollapseComponent>
        <TabDataTable
          id={group}
          headers={headers}
          rows={rows}
          isLoading={isLoading}
          overflowMenu={
            <TimeRangeMenu label={selectedItem.label} items={items} />
          }
        />
      </CollapseComponent>
      <CollapseComponent>
        {groupingValues?.length && (
          <Box mr="auto" mt={5} mb={3} minWidth="250px" maxWidth="350px">
            <Dropdown
              id={`grouping-value-${group}`}
              data-testid={`grouping-value-${group}`}
              disabled={!hasData}
              label={tagKey || ''}
              titleText={tagKey}
              items={groupingValues || []}
              itemToString={item => item?.name || ''}
              selectedItem={selectedGroupingValue}
              onChange={({ selectedItem }) => {
                if (selectedItem === null) {
                  return
                }
                setSelectedGroupingValue(selectedItem)
              }}
            />
          </Box>
        )}
        {selectedGroupingValue && (
          <GenericTabSummaryViewChart
            groupingKey={group}
            tagKey={tagKey}
            groupingValue={selectedGroupingValue.uid}
          />
        )}
      </CollapseComponent>
    </>
  )
}
