import React from 'react'
import { ReactComponent as ClusterIcon } from '../../../../../images/Cluster.svg'
import { ReactComponent as CronJobsIcon } from '../../../../../images/Cronjobs.svg'
import { ReactComponent as DaemonSetsIcon } from '../../../../../images/Daemonsets.svg'
import { ReactComponent as DeploymentsIcon } from '../../../../../images/Deployments.svg'
import { ReactComponent as JobsIcon } from '../../../../../images/Jobs.svg'
import { ReactComponent as PodsIcon } from '../../../../../images/Pods.svg'
import { ReactComponent as StatefulSetsIcon } from '../../../../../images/Statefulsets.svg'
import { ReactComponent as RolloutsIcon } from '../../../../../images/Rollouts.svg'
import { ReactComponent as VMIcon } from '../../../../../images/VM.svg'
import Text from '../../../Text'

interface TypeIconProps {
  type: string
}

const TypeIcon = ({ type }: TypeIconProps) => {
  switch (type.toLowerCase()) {
    case 'cluster':
      return <ClusterIcon />
    case 'cronjob':
      return <CronJobsIcon />
    case 'daemonset':
      return <DaemonSetsIcon />
    case 'deployment':
      return <DeploymentsIcon />
    case 'job':
      return <JobsIcon />
    case 'statefulset':
      return <StatefulSetsIcon />
    case 'vm':
      return <VMIcon />
    case 'pod':
      return <PodsIcon />
    case 'rollout':
      return <RolloutsIcon />
    default:
      console.error(`No icon defined for type "${type}"`)
      return null
  }
}

interface WorkloadTypeProps {
  type: string | undefined
  text?: string
}

export const WorkloadType = ({ type, text }: WorkloadTypeProps) => {
  if (!type) {
    return null
  }

  return <Text icon={<TypeIcon type={type} />}>{text ?? type}</Text>
}
