import { useMemo } from 'react'
import {
  Dropdown,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
} from '@carbon/react'
import { Information } from '@carbon/react/icons'
import { useTranslation } from 'react-i18next'
import { kubernetesWorkloadSummaryMetricMap } from 'src/next/constants/summary'
import { Box, Flex } from 'src/next/components'
import { TabDataTable } from 'src/next/components/dashboard/tabs/tables'
import { useGroupingHeaders } from 'src/next/components/dashboard/tabs/tables/useGroupingTable'
import { InlineNotification } from 'src/next/components/InlineNotification'
import Typography from 'src/next/components/Typography'
import { CollapseComponent } from 'src/next/components/ui'
import { TopSummariesChart } from 'src/next/components/dashboard/tabs/charts/TopSummariesChart'
import { useKubernetesTopSummaries } from 'src/next/containers/WorkloadCharts/useKubernetesTopSummaries'
import { useKubernetesWorkloadTopSummariesMetricDropdownItems } from 'src/next/containers/WorkloadCharts/KubernetesWorkloadCharts.translations'
import { useDataTableLegend } from 'src/next/hooks/useDataTableLegend'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'
import { createFormatFn, getFormatType } from 'src/next/utils/graph.utils'
import { getTimePeriod } from 'src/next/utils/time'
import { useTimeRangeOptions } from './charts/useFilterOptions'
import { transformGroupingData } from './tables/transformGroupingData'

const useLimitDropdownItems = (): (Record<'id', string> & {
  label: string
})[] => {
  const { t } = useTranslation()
  return useMemo(() => {
    return [
      ...[5, 10, 25].map(value => ({
        id: `${value}`,
        label: t('Common.TopX', {
          value,
        }),
      })),
      {
        // 500 as a sane 'all' default? to prevent rendering / ux problems
        id: '500',
        label: t('Common.ShowAll'),
      },
    ]
  }, [t])
}

export const NamespaceTab = () => {
  const { t } = useTranslation()

  const timeRangeDropdownItems = useTimeRangeOptions()
  const metricDropdownItems =
    useKubernetesWorkloadTopSummariesMetricDropdownItems()
  const limitDropdownItems = useLimitDropdownItems()

  const {
    dropdownProps: { selectedItem: timeRange, ...timeRangeDropdownProps },
  } = usePersistentDropdown(
    'dashboard-namespace-tab-time-range',
    timeRangeDropdownItems,
  )

  const {
    dropdownProps: { selectedItem: metric, ...metricDropdownProps },
  } = usePersistentDropdown(
    'dashboard-namespace-tab-metric',
    metricDropdownItems,
  )

  const {
    dropdownProps: { selectedItem: limit, ...limitDropdownProps },
  } = usePersistentDropdown('dashboard-namespace-tab-limit', limitDropdownItems)

  const { startTimeNs, endTimeNs } = getTimePeriod(timeRange.id)

  const { isLoading, data } = useKubernetesTopSummaries(
    {
      summaryType: 'NAMESPACE' as any,
      summaryInterval: timeRange.id.toUpperCase() as any,
      summaryMetrics: metric.id as any,
      limit: Number(limit.id),
      filter: {
        startTimeNs,
        endTimeNs,
      },
    },
    {
      select: data => transformGroupingData('NAMESPACE', data, undefined),
    },
  )

  const headers = useGroupingHeaders({
    metrics: kubernetesWorkloadSummaryMetricMap,
  })

  const formattedRows = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map(summary => {
      const formatted = { ...summary }
      Object.values(kubernetesWorkloadSummaryMetricMap).forEach(metric => {
        formatted[`${metric}Formatted`] = createFormatFn(
          getFormatType(metric),
          {
            cpu: { shortDisplayValue: true },
          },
        )(summary[metric])
      })
      return formatted
    })
  }, [data])

  const { rows, ...chartProps } = useDataTableLegend({
    data: formattedRows,
  })

  if ((!isLoading && !data?.length) || (!isLoading && !rows.length)) {
    return <InlineNotification title={t('Common.no_data_found')} />
  }

  return (
    <>
      <Flex alignItems="center">
        <Box mt={5} mb={3}>
          <Flex alignItems="flex-end">
            <Typography variant="productive-heading-03">
              {t('Dashboard.Tabs.KubernetesSpecific.Heading')}
            </Typography>
            <Toggletip>
              <ToggletipButton label="information">
                <Information />
              </ToggletipButton>
              <ToggletipContent>
                <div>{t('Dashboard.Tabs.KubernetesSpecific.Tooltip')}</div>
              </ToggletipContent>
            </Toggletip>
          </Flex>
          <Box color="text-secondary">
            <Typography variant="label-02">
              {t('Dashboard.Tabs.KubernetesSpecific.Subheading')}
            </Typography>
          </Box>
        </Box>
        <Flex ml="auto" gap="var(--cds-spacing-04)">
          <Dropdown
            label="Time period"
            selectedItem={timeRange}
            {...timeRangeDropdownProps}
          />
          <Dropdown
            label={t('Dashboard.Namespace.Metric')}
            selectedItem={metric}
            {...metricDropdownProps}
          />
          <Dropdown
            label={t('Dashboard.Namespace.Quantity')}
            selectedItem={limit}
            {...limitDropdownProps}
          />
        </Flex>
      </Flex>

      <>
        <CollapseComponent>
          <TopSummariesChart
            summaryType="NAMESPACE"
            timePeriod={timeRange.id}
            summaryMetric={metric.id}
            summaryLimit={limit.id}
            height={680}
            width={1616}
            padding={{ left: 60, top: 20, bottom: 50, right: 0 }}
            containerProps={{ voronoiDimension: undefined }}
            {...chartProps}
          />
        </CollapseComponent>
        <Box mb="calc(var(--cds-spacing-08) * -1)" mt={8}>
          <Typography variant="productive-heading-03">
            {t('Dashboard.Common.DataTable.Heading')}
          </Typography>
          <Box color="text-secondary">
            <Typography variant="label-02">
              {t('Dashboard.Common.DataTable.Subheading')}
            </Typography>
          </Box>
        </Box>
        <TabDataTable
          id="namespace"
          headers={headers}
          rows={rows}
          isLoading={isLoading}
        />
      </>
    </>
  )
}
