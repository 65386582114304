import React, { useCallback, useEffect, useMemo } from 'react'
import { compose } from 'lodash/fp'
import { OverflowMenuItemWithCheck } from 'src/next/components'
import { ObjWithId } from '..'
import useLocalStorage from '../useLocalStorage'

type ItemsProp<T> = ObjWithId<T & { label: string }>[]

const findSelectedItem = <T,>(items: ItemsProp<T>, selectedItemId: string) =>
  items.find(({ id }) => id === selectedItemId)

/**
 * This hook is for creating controlled Carbon OverflowMenu's whose state
 * persists in LocalStorage. It stores the id only and finds the related object
 * for `selectedItem`.
 * @example
 * const { items } = usePersistentOverflowMenu('localStorageId', OverflowMenuItems);
 * ...
 * <OverflowMenu>{items}</OverflowMenu>
 */
export const usePersistentOverflowMenu = <T extends object>(
  id: string,
  itemsProp: ItemsProp<T>,
  { onChange = (f: any) => f, defaultValue = '' } = {},
  defaultItem = 0,
) => {
  // get selected value from LocalStorage, or fallback to first item
  const { id: firstItem } = itemsProp[defaultItem]
  const [storedId, setStoredId] = useLocalStorage<string>(
    id,
    defaultValue || firstItem,
    true,
  )

  const handleOnChange = useCallback(
    selectedItemId => {
      return compose(
        () => onChange(findSelectedItem(itemsProp, selectedItemId)),
        setStoredId,
      )(selectedItemId)
    },
    [itemsProp, onChange, setStoredId],
  )

  const selectedItem = findSelectedItem(itemsProp, storedId) || itemsProp[0]
  // make sure the item in localStorage actually exists in the items array.
  // If not, reset to first item
  useEffect(() => {
    setStoredId(selectedItem.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const items = itemsProp.map(({ id: itemsId, label }) => {
    return (
      <OverflowMenuItemWithCheck
        id={itemsId}
        key={itemsId}
        label={label}
        checked={storedId === itemsId}
        onClick={handleOnChange}
      />
    )
  })

  const returnValue = useMemo(
    () => ({
      setSelectedItem: setStoredId,
      selectedItem: selectedItem!,
      items,
    }),
    [items, selectedItem, setStoredId],
  )

  return returnValue
}
