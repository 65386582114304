import React, { ReactNode } from 'react'
import { Calendar } from '@carbon/react/icons'
import { OverflowMenu } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'src/next/components'
import { TimeRangeOption } from 'src/next/components/dashboard/timerange/TimerangeTypes'
import Typography from 'src/next/components/Typography'
import { usePersistentOverflowMenu } from 'src/next/hooks/usePersistentOverflowMenu'
import { getTimeRangeOptions } from './Timerange.translations'
import { useTimeRange } from './useTimeRange'

interface Props {
  id: string
  show24HoursOption?: boolean
  /** index of the item the set as default (TODO: set default based on a key) */
  defaultItem?: number
  onChange?: (item: TimeRangeOption) => void
}

export const useTimeRangeOverflowMenu = ({
  id,
  show24HoursOption = false,
  defaultItem = 0,
  onChange,
}: Props) => {
  const { t } = useTranslation()
  const timeRangeOptions = getTimeRangeOptions({ t, show24HoursOption })

  const { items, selectedItem } = usePersistentOverflowMenu(
    id,
    timeRangeOptions,
    { onChange },
    defaultItem,
  )
  const timeRangeProps = useTimeRange(selectedItem.id)

  return {
    TimeRangeMenu,
    selectedItem,
    items,
    ...timeRangeProps,
  }
}

const TimeRangeMenu = ({
  label,
  items,
}: {
  label?: string
  items: ReactNode[]
}) => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center">
      {label && (
        <Box ml={4} mr={2}>
          <Typography variant="body-long-01">{label}</Typography>
        </Box>
      )}

      <OverflowMenu
        flipped
        iconDescription={t('Common.DateFilter.SelectTimeRange')}
        aria-label={t('Common.DateFilter.SelectTimeRange')}
        renderIcon={Calendar}
      >
        {items}
      </OverflowMenu>
    </Flex>
  )
}
