import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  LazyTabPanel,
  Tab,
  Tabs,
  TabList,
  TabPanels,
} from 'src/next/components/Tabs'
import { PageTitle } from 'src/next/components/ui'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { AuditLogPage } from './AuditLogPage'
import { OrganizationsPage } from './OrganizationsPage'
import { ResourcesPage } from './ResourcesPage'
import { RulesPage } from './RulesPage'
import { UsersPage } from './UsersPage'

export const AdministrationPage = () => {
  const { t } = useTranslation()

  const [selectedTabIndex, setSelectedTabIndex] = useLocalStorage(
    'admin-page',
    0,
  )

  const tabs = [
    {
      id: 'organizations',
      title: t('Organizations.PageTitle'),
      component: <OrganizationsPage />,
    },
    {
      id: 'resources',
      title: t('Resources.PageTitle', 'Resources'),
      component: <ResourcesPage />,
    },
    {
      id: 'rules',
      title: t('Rules.PageTitle'),
      component: <RulesPage />,
    },
    {
      id: 'users',
      title: t('Users.PageTitle'),
      component: <UsersPage />,
    },
    {
      id: 'auditlogs',
      title: t('AuditLogs.PageTitle'),
      component: <AuditLogPage />,
    },
  ]

  return (
    <>
      <PageTitle title={t('NavigationPanel.Administration')} />
      <Tabs
        selectedIndex={selectedTabIndex}
        onChange={({ selectedIndex }) => setSelectedTabIndex(selectedIndex)}
      >
        <TabList aria-label="Administration page tabs">
          {tabs.map(tab => (
            <Tab key={tab.id} id={tab.id}>
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map((tab, index) => (
            <LazyTabPanel
              key={tab.id}
              index={index}
              selectedIndex={selectedTabIndex}
            >
              {tab.component}
            </LazyTabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  )
}
