import {
  ComputeInstanceSummary,
  GetTopComputeInstanceSummariesResponse,
} from '@cloudnatix-types/dashboard'
import { Link } from 'src/next/components'
import MiddleTruncate from 'src/next/components/MiddleTruncate'
import { GraphColor } from 'src/next/types/workloads'
import { getCarbonPaletteCssVariable } from 'src/next/utils/graph.utils'

interface LocationState {
  pathname: string
  search: string
}

export const transformComputeInstanceGroupingData = (
  groupingKey: string,
  // `GetTopSummariesResponse` and `GetTopComputeInstanceSummariesResponse` differ only slightly.
  //
  // Eventually we might want to combine the endpoints of:
  // - /v1/dashboard/infrastructure/compute/instancesummaries:rollup (for generic tab) &
  // - /v1/dashboard/kubernetes/summaries:rollup (for Namespace)
  // altogether and merge the components.
  data?: GetTopComputeInstanceSummariesResponse,
  linkState?: LocationState,
): TransformedDataItem[] => {
  if (!data?.groupSummaries) return []

  const aggregatedSummaries = data.groupSummaries.map(summary => {
    const groupName = summary.groupingName!

    const nameElement = (
      <MiddleTruncate text={groupName} charsAtStart={30} charsAtEnd={30} />
    )
    return {
      ...summary,
      id: groupName,
      name:
        groupingKey === 'INSTANCE_ID' ? (
          <Link
            to={`/app/vm-workload/${encodeURIComponent(
              groupName,
            )}?tabs=recommendations`}
            state={linkState}
          >
            {nameElement}
          </Link>
        ) : (
          nameElement
        ),
    } as Omit<TransformedDataItem, 'color'>
  })

  // Convert to DataTable rows
  return aggregatedSummaries
    .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
    .map((item, i) => ({
      color: getCarbonPaletteCssVariable(i),
      ...item,
    }))
}

interface TransformedDataItem extends ComputeInstanceSummary {
  id: string
  name: JSX.Element
  color: GraphColor
}
