import groupBy from 'lodash/groupBy'
import { GetTopSummariesResponse, Summary } from '@cloudnatix-types/dashboard'
import { Link } from 'src/next/components'
import MiddleTruncate from 'src/next/components/MiddleTruncate'
import { GraphColor } from 'src/next/types/workloads'
import { getCarbonPaletteCssVariable } from 'src/next/utils/graph.utils'

interface LocationState {
  pathname: string
  search: string
}

export const transformGroupingData = (
  groupingKey: string,
  data?: GetTopSummariesResponse,
  linkState?: LocationState,
): TransformedDataItem[] => {
  if (!data?.topSummaries) return []

  // Flatten all arrays from data.topSummaries[timestamp].summaries
  const flattenedSummaries = Object.values(data.topSummaries)
    .map(value => value.summaries)
    .flat()

  // Group values with name as key
  const groupedSummaries = groupBy(
    flattenedSummaries,
    item => item?.name || item?.groupingName,
  )

  // Sum (or average) all number values per group (and ungroup at the same time).
  const aggregatedSummaries = Object.entries(groupedSummaries).map(
    ([groupName, summaries]) => {
      const nameElement = (
        <MiddleTruncate text={groupName} charsAtStart={30} charsAtEnd={30} />
      )
      const aggregatedValues = summaries.reduce(
        (currentSums, summary) => {
          Object.entries(summary!).forEach(([field, value]) => {
            // We sum all number fields (e.g. avgCpu). The others e.g.
            // timestampNs, groupingName, and groupingValue are dropped here.
            if (typeof value !== 'number') {
              return
            }

            if (currentSums[field]) {
              currentSums[field] = Number(currentSums[field]) + value
            } else {
              currentSums[field] = value
            }
          })

          // At this point we also have no grouping anymore
          return currentSums
        },
        {
          id: groupName,
          name:
            groupingKey === 'INSTANCE_ID' ? (
              <Link
                to={`/app/vm-workload/${encodeURIComponent(
                  groupName,
                )}?tabs=recommendations`}
                state={linkState}
              >
                {nameElement}
              </Link>
            ) : (
              nameElement
            ),
        } as Omit<TransformedDataItem, 'color'>,
      )

      // Convert sum values to average except the columns representing cost.
      //
      // 'totalCost' is for the Kubernetes workload summary. 'totalSpend' is for
      // the compute instance summary.
      //
      // TODO(kenji): Move the logic to the backend.
      Object.entries(aggregatedValues).forEach(([column, sum]) => {
        if (
          typeof sum === 'number' &&
          column !== 'totalCost' &&
          column !== 'totalSpend' &&
          column !== 'wastedSpend'
        ) {
          aggregatedValues[column] = Number(sum) / summaries.length
        }
      })

      return aggregatedValues
    },
  )

  // Convert to DataTable rows
  return aggregatedSummaries
    .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
    .map((item, i) => ({
      color: getCarbonPaletteCssVariable(i),
      ...item,
    }))
}

interface TransformedDataItem extends Omit<Summary, 'name'> {
  id: string
  name: JSX.Element
  color: GraphColor
}
