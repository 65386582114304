import React, { useEffect, useMemo } from 'react'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import FilterableSelect from 'src/next/components/FilterableSelect/FilterableSelect'
import { useInstanceTagKeysQuery } from './hooks/useInstanceTagKeysQuery'

interface TagKeySelectProps {
  setTagKey: (tagKey: string) => void
}

export const TagKeySelect = ({ setTagKey }: TagKeySelectProps) => {
  const { t } = useTranslation()

  const { data: instanceTagKeys } = useInstanceTagKeysQuery()

  // sort the items, since the order of items being returned is not stable
  const sortedItems = useMemo(
    () => orderBy(instanceTagKeys?.keys, item => item.toLowerCase()),
    [instanceTagKeys],
  )

  useEffect(() => {
    if (sortedItems.length) setTagKey(sortedItems[0])
  }, [sortedItems])

  return (
    <FilterableSelect
      items={sortedItems || []}
      onChange={setTagKey}
      placeholder={t('Dashboard.Tabs.SelectTagKey')}
      id="instance-tag-keys"
    />
  )
}
