import { Column, Grid } from '@carbon/react'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import { Box, ErrorBoundary } from 'src/next/components'
import { GenericTabSummaryViewDonutChart } from './GenericTabSummaryViewDonutChart'
import { GenericTabSummaryViewLineChart } from './GenericTabSummaryViewLineChart'

export interface GenericTabSummaryViewChartProps {
  groupingKey: `${ComputeInstanceGroupingKeyKey}`
  groupingValue?: string | null
  tagKey?: string | null
}

export const GenericTabSummaryViewChart = ({
  groupingKey,
  groupingValue,
  tagKey = undefined,
}: GenericTabSummaryViewChartProps) => {
  return (
    <Box
      mt="var(--cds-spacing-05)"
      mb="var(--cds-spacing-05)"
      minHeight="580px"
    >
      <Grid fullWidth narrow>
        <Column sm={4} md={8}>
          <ErrorBoundary resetKeys={[groupingKey, groupingValue, tagKey]}>
            <GenericTabSummaryViewLineChart
              groupingKey={groupingKey}
              groupingValue={groupingValue}
              tagKey={tagKey}
            />
          </ErrorBoundary>
        </Column>
        <Column sm={4} md={8}>
          <ErrorBoundary resetKeys={[groupingKey, groupingValue, tagKey]}>
            <GenericTabSummaryViewDonutChart
              groupingKey={groupingKey}
              groupingValue={groupingValue}
              tagKey={tagKey}
            />
          </ErrorBoundary>
        </Column>
      </Grid>
    </Box>
  )
}
