import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
  ComputeInstanceGroupingKeyKey,
  ComputeInstanceSummary,
} from '@cloudnatix-types/dashboard'
import { getFilterGroupingDuration, useInstanceSummaries } from 'src/api'
import { LineGraphProps, useLegend } from 'src/next/components'
import { Graph } from 'src/next/components/Graphs/Graph'
import GraphTooltip from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import { LoadingWrapper } from 'src/next/components/LoadingWrapper'
import { getCarbonPaletteCssVariable } from 'src/next/utils/graph.utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'
import { useTimeRangeOverflowMenu } from '../../timerange'
import { GenericTabSummaryViewChartHeading } from './GenericTabSummaryViewChartHeading'

export interface GenericTabSummaryViewLineChartProps {
  groupingKey: `${ComputeInstanceGroupingKeyKey}`
  groupingValue?: string | null
  tagKey?: string | null
}

const getTooltipHeading = (activePoint: any) => {
  const date = dayjs(activePoint?.x)
  return date.isValid() ? date.format('LL') : ''
}

export const GenericTabSummaryViewLineChart = ({
  groupingKey,
  groupingValue,
  tagKey,
}: GenericTabSummaryViewLineChartProps) => {
  const { t } = useTranslation()

  const { TimeRangeMenu, items, selectedItem, startTimeNs, endTimeNs } =
    useTimeRangeOverflowMenu({
      id: `dashboard-tab-${groupingKey}-tab-line-chart-timerange`,
      show24HoursOption: false,
    })

  const { data, isLoading } = useInstanceSummaries(
    {
      filter: {
        startTimeNs,
        endTimeNs,
        grouping: {
          groupingKey: {
            key: groupingKey as any,
            tagKey: tagKey as any,
          },
          groupingValue: groupingValue as any,
          duration: getFilterGroupingDuration(selectedItem.id) as any,
        },
      },
    },
    {
      useErrorBoundary: true,
    },
  )

  const graphData = useGraphData(data?.summaries)

  const { Legend, graphConfig, legendProps } = useLegend(graphData || [])

  return (
    <>
      <GenericTabSummaryViewChartHeading
        title={t('Dashboard.Common.Efficiency')}
        overflowMenu={
          <TimeRangeMenu label={selectedItem.label} items={items} />
        }
      />

      <LoadingWrapper isLoading={isLoading}>
        <Graph
          data={graphConfig}
          unit="percentage"
          height={510}
          xAxis={[
            {
              // custom tick values to correct auto tz behaviour Victory
              tickValues: graphConfig?.[0]?.data?.map(
                d => new Date(Number(d.x)),
              ),
              tickFormat: x => dayjs(x).format('MM/DD'),
            },
          ]}
          domain={{ y: [0, 100] }}
          containerComponent={GraphTooltipContainer(
            <GraphTooltip
              graphConfig={graphConfig}
              heading={getTooltipHeading}
            />,
          )}
          scatter
        />
      </LoadingWrapper>
      <Legend {...legendProps} />
    </>
  )
}

const useGraphData = (summaries?: ComputeInstanceSummary[] | undefined) => {
  const metrics = useMetrics()

  return useMemo(() => {
    if (!summaries?.length) return []
    const allData = summaries
    const timestamps = allData.map(o => o.timestampNs)

    // the api returns duplicates
    const uniqueData = allData.filter(
      ({ timestampNs }, index) => !timestamps.includes(timestampNs, index + 1),
    )

    return metrics.map(({ id, label, yKey }, i) => {
      return {
        id,
        type: 'line',
        label: `${label} (%)`,
        tooltipLabel: label,
        tooltipValueTransformFn: (data: any) => `${data}%`,
        props: { style: { data: { stroke: getCarbonPaletteCssVariable(i) } } },
        data: uniqueData.map(group => {
          const value = Number(group[yKey as keyof ComputeInstanceSummary]!)
          return {
            x: nanoToMilliSeconds(group.timestampNs!),
            y: +(value! * 100).toFixed(0),
          }
        }),
      }
    }) as LineGraphProps[]
  }, [summaries, metrics])
}

const useMetrics = () => {
  const { t } = useTranslation()
  return useMemo(
    () => [
      {
        id: 'line-chart-cpu',
        yKey: 'cpuEfficiency',
        label: t('Dashboard.Common.CPUEfficiency'),
      },
      {
        id: 'line-chart-mem',
        yKey: 'memoryEfficiency',
        label: t('Dashboard.Common.MemoryEfficiency'),
      },
      {
        id: 'line-chart-cost',
        yKey: 'efficiency',
        label: t('Dashboard.Common.CostEfficiency'),
      },
    ],
    [t],
  )
}
