import React from 'react'
import { RulesTable } from 'src/next/components/administration/Rules'
import RulesTableFilters from 'src/next/components/administration/Rules/RulesTableFilters'
import {
  TableFilterContainer,
  TableFilterContextProvider,
} from 'src/next/components/TableFilter'

export const RulesPage = () => {
  return (
    <TableFilterContextProvider localStorageId="admin-rules-filters">
      <TableFilterContainer>
        <RulesTableFilters />
        <RulesTable />
      </TableFilterContainer>
    </TableFilterContextProvider>
  )
}
